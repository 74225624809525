import React from "react"
import { Heading, StyledLink, Box, List } from "../../elements"
import checkIcon from "../../images/traefik/check-icon.svg"
import styled from "styled-components"

const Feature = ({ icon, title, list, link }) => (
  <Box
    p={["24px", null, "48px"]}
    bg="background.base"
    borderRadius="6px"
    border="solid 1px #ebeaeb"
    background="radial-gradient(circle at 0% 0%, #ebfbff, rgba(42, 162, 193, 0) 31%), linear-gradient(to bottom, #fff, #fff);"
    boxShadow="0 6px 12px 0 rgba(31, 3, 35, 0.03)"
  >
    <img src={icon} alt={title} />
    <Heading as="h3" variant="sm" mt="24px">
      {title}
    </Heading>
    <CheckList icon={checkIcon} mt="24px" color="text.base">
      {list.map(item => (
        <List.CustomBulletListItem key={item}>{item}</List.CustomBulletListItem>
      ))}
    </CheckList>
    {link.isExternal ? (
      <StyledLink icon="arrow" href={link.href} color="#54b4cd" mt="26px">
        Explore
      </StyledLink>
    ) : (
      <StyledLink icon="arrow" to={link.href} color="#54b4cd" mt="26px">
        Explore
      </StyledLink>
    )}
  </Box>
)

const CheckList = styled(List.CustomBulletList).attrs(props => ({
  icon: checkIcon,
}))`
  li {
    line-height: 1.5;
    margin: 0 0 24px;

    &:last-child {
      margin: 0;
    }
  }
`

export default Feature
