import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import {
  BoxedContainer,
  Box,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Button,
} from "../elements"
import { padding } from "../styles/theme"
import {
  ButtonPrimary as ButtonPrimaryHub,
  ButtonSecondary as ButtonSecondaryHub,
} from "../components/hub/Buttons"
import UseCase from "../components/proxy/UseCase"
import Feature from "../components/proxy/Feature"
import Arrow from "../images/inline-svgs/arrow-right.svg"

// Import styles
import "../styles/traefik.scss"

// Images
import traefik_logo from "../images/logos/traefik-proxy-logo--white.svg"
import intro__downloads_icon from "../images/traefik/intro/downloads-icon.svg"
import intro__github_icon from "../images/traefik/intro/github-icon.svg"
import intro__contributors_icon from "../images/traefik/intro/contributors-icon.svg"
import customers__mozilla from "../images/traefik/customers/mozilla.svg"
import customers__cond_nast from "../images/traefik/customers/cond-nast.svg"
import customers__expedia from "../images/traefik/customers/expedia.svg"
import customers__worldline from "../images/traefik/customers/worldline.svg"
import customers__bose from "../images/traefik/customers/bose.svg"
import customers__sainsbury from "../images/traefik/customers/sainsbury.svg"
import customers__mailchimp from "../images/traefik/customers/mailchimp.svg"
import customers__rackspace from "../images/traefik/customers/rackspace.svg"
import customers__talend from "../images/traefik/customers/talend.svg"
import customers__newrelic from "../images/traefik/customers/NewRelic.svg"
import customers__ovh from "../images/traefik/customers/ovh-cloud.svg"
import usecases__loadbalancing from "../images/traefik/use-cases/load-balancing.svg"
import usecases__apigateway from "../images/traefik/use-cases/api-gateway.svg"
import usecases__kubernetes from "../images/traefik/use-cases/kubernetes-ingress.svg"
import usecases__certificate from "../images/traefik/use-cases/certificate-management.svg"
import features__routing from "../images/traefik/features/routing.svg"
import features__secure from "../images/traefik/features/secure.svg"
import features__config from "../images/traefik/features/dynamic-configuration.svg"
import features__monitoring from "../images/traefik/features/monitoring.svg"
import ecosystem__consul from "../images/traefik/ecosystem/consul.svg"
import ecosystem__docker from "../images/traefik/ecosystem/docker.svg"
import ecosystem__kubernetes from "../images/traefik/ecosystem/kubernetes.svg"
import ecosystem__redis from "../images/traefik/ecosystem/redis.svg"
import ecosystem__instana from "../images/traefik/ecosystem/instana.svg"
import ecosystem__k3s from "../images/traefik/ecosystem/k3s.svg"
import ecosystem__hashicorp from "../images/traefik/ecosystem/hashicorp-nomad.svg"
import cta__download from "../images/traefik/cta/download-icon.svg"
import cta__getstarted from "../images/traefik/cta/get-started-icon.svg"
import cta__community from "../images/traefik/cta/community-icon.svg"
import featuresCtaBg from "../images/traefik/features/hero-illustration.svg"

const getRelatedResourceLinkText = (tag_slug: string) => {
  let link_text = "Read More"
  switch (tag_slug) {
    case "white-paper":
      link_text = "Read White Paper"
      break
    case "datasheet":
      link_text = "Read Datasheet"
      break
    case "case-study":
      link_text = "Read Case Study"
      break
    case "webinar":
      link_text = "Watch Webinar"
      break
    case "video":
      link_text = "Watch Video"
      break
    default:
      link_text = "Read More"
  }
  return link_text
}

interface Props {
  data: any
}

const TraefikPage = ({ data, location }: Props) => {
  const related_resources = data.related_resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="traefik-page">
        <Box
          as="section"
          className="traefik-intro"
          bg="#03192d"
          py={padding.yaxis.default}
          position="relative"
        >
          <Box position="absolute" top="0" right="0" width="100%" height="100%">
            <StaticImage
              src="../images/traefik/intro/bg@3x.png"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              objectPosition="top center"
              style={{
                height: "100%",
              }}
              imgStyle={{
                height: "100%",
              }}
              formats={["auto", "webp", "avif"]}
              placeholder="none"
              quality={90}
            />
          </Box>
          <Box position="relative" className="top">
            <div className="traefik-intro__main">
              <div
                className="traefik-intro__updates"
                dangerouslySetInnerHTML={{
                  __html: data.traefik_announcement?.html,
                }}
              ></div>

              <Flex height="64px">
                <Heading variant="lg" as="h1">
                  <img
                    src={traefik_logo}
                    alt="Traefik - Open Source load balancer and reverse proxy for microservices"
                    style={{
                      maxHeight: "100%",
                    }}
                  />
                </Heading>
              </Flex>

              <Heading
                mt="40px"
                variant="lg"
                as="h2"
                color="text.inverted.base"
                maxWidth="600px"
              >
                The Cloud Native Application Proxy
              </Heading>

              <Paragraph
                as="p"
                variant="lg"
                color="#b3bac0"
                mt="24px"
                maxWidth="600px"
              >
                Simplify networking complexity while designing, deploying, and
                operating applications.
              </Paragraph>

              <Flex
                flexWrap="wrap"
                mt="32px"
                className="traefik-intro__get-started"
              >
                <a
                  className="button--primary button--with-shadow button--extra-padding button--github button--primary--no-gradient button--primary--teal"
                  href="https://github.com/traefik/traefik/releases"
                >
                  Github
                </a>
                <a
                  className="button--primary button--with-shadow button--extra-padding button--primary--no-gradient button--primary--white"
                  href="https://docs.traefik.io/"
                >
                  Get Started
                </a>
              </Flex>
            </div>

            <Flex justifyContent="flex-end">
              <StaticImage
                src="../images/traefik/intro/traefik-dashboard.png"
                style={{ height: "100%" }}
                imgStyle={{ objectPosition: "left" }}
                alt="Traefik Dashboard"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp", "avif"]}
                width={1000}
              />
            </Flex>
          </Box>
          <Box position="relative" className="bottom">
            <div className="traefik-stats">
              <div className="statistics">
                <div className="statistic">
                  <div className="icon">
                    <img src={intro__downloads_icon} />
                  </div>
                  <div className="content">
                    <div className="title">3 Billion+</div>
                    <div className="sub-title">Downloads</div>
                  </div>
                </div>
                <div className="statistic statistic--github">
                  <div className="icon">
                    <img src={intro__github_icon} />
                  </div>
                  <div className="content">
                    <div className="title">35K+</div>
                    <div className="sub-title">Stars on Github</div>
                  </div>
                </div>
                <div className="statistic">
                  <div className="icon">
                    <img src={intro__contributors_icon} />
                  </div>
                  <div className="content">
                    <div className="title">600+</div>
                    <div className="sub-title">Contributors</div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>

        {/* Customers */}
        <Box
          as="section"
          className="traefik-customers"
          bg="#f9fafa"
          pt={["60px"]}
          pb={["80px"]}
        >
          <BoxedContainer>
            <Heading as="div" variant="uppercase" mb="40px" textAlign="center">
              Companies that trust Traefik
            </Heading>
            <div className="customers">
              <div className="customer">
                <img src={customers__mozilla} alt="mozilla" />
              </div>

              <div className="customer">
                <StaticImage
                  src="../images/traefik/customers/ebay/ebay.jpg"
                  alt="Ebay Classifieds"
                  layout="fixed"
                  width={110}
                  height={61}
                />
              </div>

              <div className="customer">
                <img src={customers__cond_nast} alt="condé nast" />
              </div>

              <div className="customer">
                <img src={customers__expedia} alt="expedia" />
              </div>

              <div className="customer">
                <img src={customers__worldline} alt="worldline" />
              </div>

              <div className="customer">
                <img src={customers__bose} alt="bose" />
              </div>

              <div className="customer">
                <img src={customers__sainsbury} alt="sainsburys" />
              </div>

              <div className="customer">
                <img src={customers__mailchimp} alt="mailchimp" />
              </div>

              <div className="customer">
                <img src={customers__rackspace} alt="rackspace" />
              </div>

              <div className="customer">
                <img src={customers__talend} alt="talend" />
              </div>

              <div className="customer">
                <img src={customers__newrelic} alt="New Relic" />
              </div>

              <div className="customer">
                <img src={customers__ovh} alt="ovh" />
              </div>
            </div>
          </BoxedContainer>
        </Box>

        {/* Details Section */}
        <Box
          as="section"
          bg="background.base"
          pt={padding.yaxis.large}
          pb={padding.yaxis.large}
        >
          <BoxedContainer>
            <Grid
              gridTemplateColumns={["1fr", null, null, "496px auto"]}
              gridGap={["60px", null, null, "80px"]}
              justifyItems={["center", null, null, "start"]}
            >
              <Box>
                <Heading as="h2" variant="md">
                  What is Traefik?
                </Heading>
                <Paragraph as="p" variant="md" mt="16px" color="text.base">
                  Traefik is a leading modern reverse proxy and load balancer
                  that makes deploying microservices easy. Traefik integrates
                  with your existing infrastructure components and configures
                  itself automatically and dynamically.
                </Paragraph>
                <Paragraph as="p" variant="md" mt="24px" color="text.base">
                  Traefik is designed to be as simple as possible to operate,
                  but capable of handling large, highly-complex deployments
                  across a wide range of environments and protocols in public,
                  private, and hybrid clouds. It also comes with a powerful set
                  of middlewares that enhance its capabilities to include load
                  balancing, API gateway, orchestrator ingress, as well as
                  east-west service communication and more.
                </Paragraph>
                <Paragraph as="p" variant="md" mt="24px" color="text.base">
                  Run Traefik and let it do the work for you!
                </Paragraph>
              </Box>
              <Flex>
                <StaticImage
                  src="../images/traefik/details/traefik-dashboard@3x.png"
                  alt="Traefik Dashboard"
                  width={800}
                  quality={90}
                  objectFit="contain"
                  formats={["auto", "webp", "avif"]}
                  placeholder="none"
                />
              </Flex>
            </Grid>
          </BoxedContainer>
        </Box>

        <Box bg="product.hub.bg2x" position="relative">
          <Box
            display={["none", null, null, "block"]}
            position="absolute"
            top="0"
            right="0"
            width="100%"
            height="100%"
          >
            <StaticImage
              src="../images/traefik/hub/bg@3x.png"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              objectPosition=" center"
              style={{
                height: "100%",
              }}
              imgStyle={{
                height: "100%",
              }}
              formats={["auto", "webp", "avif"]}
              placeholder="none"
            />
          </Box>

          <BoxedContainer position="relative" zIndex={2}>
            <Flex
              flexDirection="column"
              py={padding.yaxis.large}
              maxWidth="600px"
              textAlign={["center", null, "left"]}
              alignItems={["center", null, "flex-start"]}
              mx={["auto", null, "0"]}
            >
              <Box>
                <StaticImage
                  src="../images/traefik/hub/logos-traefik-hub-beta-horizontal@3x.png"
                  alt="Traefik Hub Beta"
                  width={216}
                  height={70}
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  placeholder="none"
                />
              </Box>
              <Heading
                mt="32px"
                as="h2"
                variant="lg"
                color="text.inverted.base"
              >
                Your Cloud Native Networking Platform
              </Heading>
              <Flex>
                <ButtonPrimaryHub
                  elementType="link"
                  href="https://hub.traefik.io/"
                  mt={`32px`}
                >
                  Get Started
                </ButtonPrimaryHub>
                <ButtonSecondaryHub
                  elementType="gatsby_link"
                  to="/traefik-hub/"
                  ml={`16px`}
                  mt={`32px`}
                >
                  Learn more
                </ButtonSecondaryHub>
              </Flex>
            </Flex>
          </BoxedContainer>

          <Box
            position={["relative", null, "absolute"]}
            top="0"
            right="0"
            width="auto"
            height="100%"
            zIndex={1}
          >
            <StaticImage
              src="../images/traefik/hub/traefik-hub-dashboard.png"
              alt=""
              objectFit="contain"
              objectPosition="center right"
              style={{
                height: "100%",
              }}
              imgStyle={{
                height: "100%",
              }}
              formats={["auto", "webp", "avif"]}
              placeholder="none"
            />
          </Box>
        </Box>

        {/* Architecture Section */}
        <Box
          py={padding.yaxis.large}
          className="traefik-architecture page-content-section"
        >
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <Heading as="h2" variant="md">
                Traefik Architecture Overview
              </Heading>
            </div>
            <div className="traefik-architecture__body">
              <div className="content">
                <p>
                  Traefik intercepts and routes every incoming request to the
                  corresponding backend services.
                </p>
                <p>
                  Unlike a traditional, statically configured reverse proxy,
                  Traefik uses service discovery to configure itself dynamically
                  from the services themselves. All major protocols are
                  supported and can be flexibly managed with a rich set of
                  configurable middlewares for load balancing, rate-limiting,
                  circuit-breakers, mirroring, authentication, and more.
                </p>
                <p>
                  Traefik also supports SSL termination and can be used with an
                  ACME provider (like Let’s Encrypt) for automatic certificate
                  generation.
                </p>
                <p>
                  Traefik’s extensive features and capabilities stack up to make
                  it the comprehensive gateway to all of your applications.
                </p>
              </div>
              <div className="image">
                <StaticImage
                  src="../images/traefik/architecture/diagram.png"
                  style={{ height: "100%" }}
                  imgStyle={{ objectPosition: "left" }}
                  alt="Traefik Diagram"
                  loading="lazy"
                  width={800}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </Box>

        {/* Use Cases */}
        <Box
          bg="#03192d"
          py={padding.yaxis.large}
          className="traefik-use-cases"
        >
          <BoxedContainer>
            <Heading
              textAlign="center"
              as="h2"
              variant="md"
              color="text.inverted.base"
            >
              Traefik Use Cases
            </Heading>
            <Grid
              gridTemplateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(4, 1fr)",
              ]}
              gridGap={["60px", "36px", null, null, "56px"]}
              mt={padding.yaxis.default}
            >
              <UseCase
                title="Load Balancing"
                text="Control load to upstream services with flexible layer 4 and
                  layer 7 routing and load balancing capabilities plus a large
                  middlewares toolkit that enables dynamic scaling,
                  zero-downtime blue-green, and canary deployments, mirroring,
                  and more."
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/routing/overview/",
                }}
                icon={usecases__loadbalancing}
              />

              <UseCase
                title="API Gateway"
                text={
                  <>
                    Use Traefik as a reverse proxy in front of API services and
                    Treafik’s expanding middlewares toolkit for offloading of
                    cross-cutting concerns including authentication, rate
                    limiting, and SSL termination. Additional API gateway
                    capabilities and tooling are{" "}
                    <Link
                      to="/traefik-enterprise/"
                      style={{ color: "#54b4cd" }}
                    >
                      available for enterprises in Traefik Enterprise.
                    </Link>
                  </>
                }
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/middlewares/overview/",
                }}
                icon={usecases__apigateway}
              />

              <UseCase
                title="Kubernetes Ingress"
                text="Deploy Traefik as your Kubernetes Ingress Controller to bring
                  Traefik’s power, flexibility, and ease of use to your
                  Kubernetes deployments as well as the rest of your network
                  infrastructure."
                link={{
                  isExternal: false,
                  href: "/solutions/kubernetes-ingress/",
                }}
                icon={usecases__kubernetes}
              />

              <UseCase
                title="Certificate Management"
                text={
                  <>
                    Traefik provides built-in support for Let’s Encrypt (ACME)
                    automatic certificate management as well as
                    dynamically-updatable, user-defined certificates. For
                    Kubernetes and other high-availability deployments,{" "}
                    <Link
                      to="/traefik-enterprise/"
                      style={{ color: "#54b4cd" }}
                    >
                      Traefik Enterprise offers distributed Let’s Encrypt
                      support.
                    </Link>
                  </>
                }
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/https/overview/",
                }}
                icon={usecases__certificate}
              />
            </Grid>
            <Box mt={padding.yaxis.default} textAlign="center">
              <Button
                elementType="gatsby_link"
                to="/success-stories/"
                hollow
                arrow
                color="#54b4cd"
                borderColor="#54b4cd"
                hover={{
                  bg: "#54b4cd",
                  color: "#03192d",
                }}
              >
                Traefik Success Stories
              </Button>
            </Box>
          </BoxedContainer>
        </Box>

        <Box pt={padding.yaxis.large} bg="#f9fafa">
          <BoxedContainer>
            <Box mx="auto" maxWidth="808px" textAlign="center">
              <Heading as="h2" variant="md">
                Traefik Features
              </Heading>
              <Paragraph as="p" variant="md" mt="16px" color="text.base">
                Traefik offers a full, production-hardened feature set to meet
                the requirements of modern, cloud-native applications in any
                environment and can integrate with legacy systems across
                multi-cloud, hybrid-cloud, and on-premises deployments.
              </Paragraph>
            </Box>
            <Grid
              mt={padding.yaxis.default}
              gridTemplateColumns={["1fr", "repeat(2, 1fr)"]}
              gridGap={"24px"}
              maxWidth="1016px"
              mx="auto"
            >
              <Feature
                title="Routing &amp; load balancing"
                icon={features__routing}
                list={[
                  "Flexible layer 4 and layer 7 routing",
                  "HTTP, HTTP/2, TCP, UDP, Websockets, gRPC",
                  "Mirroring",
                  "Blue-green and canary deployments",
                  "Stickiness",
                  "Active health checks",
                ]}
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/routing/overview/",
                }}
              />
              <Feature
                title="Security"
                icon={features__secure}
                list={[
                  "Automatic HTTPS",
                  "Let’s Encrypt support",
                  "Custom certificates",
                  "Authentication",
                ]}
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/https/overview/",
                }}
              />
              <Feature
                title="Dynamic Configuration"
                icon={features__config}
                list={[
                  "Services auto-discovery (Kubernetes, Docker Swarm, Red Hat OpenShift, Rancher, Amazon ECS, key-value stores)",
                  "Middlewares (circuit breakers, automatic retries, buffering, response compression, headers, rate limiting)",
                ]}
                link={{
                  isExternal: true,
                  href: "https://docs.traefik.io/providers/overview/",
                }}
              />
              <Feature
                title="Observability"
                icon={features__monitoring}
                list={[
                  "Built-in dashboard",
                  "Distributed tracing (Jaeger, Open Tracing, Zipkin)",
                  "Real-time traffic metrics (Datadog, Grafana, InfluxDB, Prometheus, StatsD)",
                ]}
                link={{
                  isExternal: true,
                  href:
                    "https://docs.traefik.io/observability/metrics/overview/",
                }}
              />
            </Grid>
            <Box
              bg="#03192d"
              mt={padding.yaxis.large}
              borderRadius="16px"
              p="40px"
              boxShadow="0 12px 50px 0 rgba(6, 16, 42, 0.38)"
              position="relative"
              overflow="hidden"
              backgroundImage={[null, null, null, `url(${featuresCtaBg})`]}
              backgroundRepeat="no-repeat"
              backgroundPosition="right -5% center"
            >
              <Flex
                position="relative"
                flexDirection="column"
                alignItems={["center", null, null, "flex-start"]}
                textAlign={["center", null, null, "left"]}
              >
                <Heading as="h2" variant="md" color="text.inverted.base">
                  Need Enterprise Capabilities?
                </Heading>
                <Paragraph
                  mt="8px"
                  variant="md"
                  color="#b3bac0"
                  maxWidth="808px"
                >
                  Traefik Enterprise provides built-in high availability,
                  scalability, and security features required by large-scale and
                  mission-critical applications and includes enterprise support
                  offerings from the Traefik core team.
                </Paragraph>
                <Button
                  elementType="gatsby_link"
                  to="/pricing/"
                  hollow
                  arrow
                  color="white"
                  borderColor="white"
                  hover={{
                    bg: "white",
                    color: "#03192d",
                  }}
                  mt={["24px", null, null, "47px"]}
                >
                  Compare Traefik and Traefik Enterprise
                </Button>
              </Flex>
            </Box>
          </BoxedContainer>
        </Box>

        <Box
          as="section"
          py={padding.yaxis.large}
          bg="#f9fafa"
          className="traefik-ecosystem"
        >
          <BoxedContainer>
            <Box
              mx="auto"
              maxWidth="808px"
              textAlign="center"
              mb={padding.yaxis.default}
            >
              <Heading as="h2" variant="md">
                Traefik Ecosystem
              </Heading>
              <Paragraph as="p" variant="md" mt="16px" color="text.base">
                Traefik integrates with every major cluster technology and
                includes built-in support for the top distributed tracing and
                metrics providers.
              </Paragraph>
            </Box>

            <div className="ecosystem">
              <Heading as="h3" variant="sm">
                Providers &amp; Orchestrators
              </Heading>
              <div className="ecosystem-items">
                <div className="ecosystem-item">
                  <img src={ecosystem__kubernetes} alt="kubernetes" />
                </div>
                <div className="ecosystem-item">
                  <img src={ecosystem__docker} alt="docker" />
                </div>
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.etcd.childImageSharp.gatsbyImageData}
                    alt="etcd"
                  />
                </div>
                <div className="ecosystem-item">
                  <img src={ecosystem__consul} alt="consul" />
                </div>
                <div className="ecosystem-item">
                  <img src={ecosystem__hashicorp} alt="Hashicorp Nomad" />
                </div>

                <div className="ecosystem-item">
                  <img src={ecosystem__redis} alt="redis" />
                </div>

                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.amazonEcs.childImageSharp.gatsbyImageData}
                    alt="Amazon ECS"
                  />
                </div>
              </div>
            </div>

            <div className="ecosystem">
              <Heading as="h3" variant="sm">
                K8s distribution with Traefik
              </Heading>
              <div className="ecosystem-items">
                <div className="ecosystem-item">
                  <img src={ecosystem__k3s} alt="K3S" />
                </div>
              </div>
            </div>

            <div className="ecosystem">
              <Heading as="h3" variant="sm">
                Tracing
              </Heading>
              <div className="ecosystem-items">
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.jaeger.childImageSharp.gatsbyImageData}
                    alt="jaeger"
                  />
                </div>
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.opentracing.childImageSharp.gatsbyImageData}
                    alt="opentracing"
                  />
                </div>
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.zipkin.childImageSharp.gatsbyImageData}
                    alt="zipkin"
                  />
                </div>
              </div>
            </div>

            <div className="ecosystem">
              <Heading as="h3" variant="sm">
                Metrics
              </Heading>
              <div className="ecosystem-items">
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.datadog.childImageSharp.gatsbyImageData}
                    alt="datadog"
                  />
                </div>
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.prometheus.childImageSharp.gatsbyImageData}
                    alt="prometheus"
                  />
                </div>
                <div className="ecosystem-item">
                  <GatsbyImage
                    image={data.influxdata.childImageSharp.gatsbyImageData}
                    alt="influx data"
                  />
                </div>
              </div>
            </div>
          </BoxedContainer>
        </Box>

        {/* Related Resources */}
        <Box className="traefik-resources" py={padding.yaxis.large}>
          <BoxedContainer>
            <Box mx="auto" textAlign="center" mb={padding.yaxis.default}>
              <Heading as="h2" variant="md">
                Learn About Traefik
              </Heading>
            </Box>

            <Grid
              gridTemplateColumns={[
                "1fr",
                "repeat(2, 1fr)",
                null,
                "repeat(3, 1fr)",
              ]}
              gridGap="24px"
              mb={padding.yaxis.default}
            >
              {related_resources.map(({ node }) => (
                <Link
                  to={`/${node.primary_tag.slug}/${node.slug}/`}
                  className={
                    `card ` +
                    (node.primary_tag.slug === "blog"
                      ? "card--blog"
                      : `card--${node.tags[1]?.slug}`)
                  }
                  key={node.id}
                >
                  <div className="card__tag">
                    {node.primary_tag.slug === "blog"
                      ? "Blog"
                      : node.tags[1]?.name}
                  </div>
                  <Heading
                    as="h3"
                    variant="xs"
                    fontSize={["1.8rem", null, "2rem"]}
                    fontWeight="medium"
                  >
                    {node.title}
                  </Heading>
                  <Flex flex="1"></Flex>
                  <Flex alignItems="center" color="#03192d" mt="8px">
                    <Heading
                      as="div"
                      variant="xs"
                      fontSize="1.6rem"
                      fontWeight="medium"
                    >
                      {getRelatedResourceLinkText(node.tags[1]?.slug)}
                    </Heading>
                    <Flex width="24px" height="24px" ml="6px">
                      <Arrow />
                    </Flex>
                  </Flex>
                </Link>
              ))}
            </Grid>

            <Box>
              <Heading as="h3" variant="sm" mb="16px" textAlign="center">
                Want to know more about Traefik?
              </Heading>
              <Flex justifyContent="center">
                <Button
                  elementType="gatsby_link"
                  uppercase
                  large
                  bg="#24a1c1"
                  to="/resources/"
                  hover={{
                    bg: "hsl(192deg, 69%, 41%)",
                  }}
                >
                  Visit the Resource Library
                </Button>
              </Flex>
            </Box>
          </BoxedContainer>
        </Box>

        {/* CTA Section */}
        <Box
          as="section"
          className="traefik-cta"
          py={padding.yaxis.large}
          position="relative"
          bg="#03192d"
        >
          <Box position="absolute" top="0" right="0" width="100%" height="100%">
            <StaticImage
              src="../images/traefik/cta/bg-traefik@3x.png"
              alt=""
              layout="fullWidth"
              objectFit="cover"
              objectPosition="bottom center"
              style={{
                height: "100%",
              }}
              imgStyle={{
                height: "100%",
              }}
              formats={["auto", "webp", "avif"]}
              placeholder="none"
              quality={90}
            />
          </Box>

          <BoxedContainer position="relative">
            <Heading
              as="h2"
              variant="md"
              mb={padding.yaxis.default}
              textAlign="center"
              color="text.inverted.base"
            >
              Ready to get started?
            </Heading>
            <Grid
              gridTemplateColumns={["1fr", null, "repeat(3, 1fr)"]}
              gridGap="24px"
            >
              <a
                href="https://docs.traefik.io/getting-started/install-traefik"
                className="link link--download"
              >
                <img src={cta__download} />
                <span>Download</span>
              </a>
              <a
                href="https://docs.traefik.io/"
                className="link link--get-started"
              >
                <img src={cta__getstarted} />
                <span>Get Started with Traefik</span>
              </a>
              <Link to="/community/" className="link link--community">
                <img src={cta__community} />
                <span>Join the Community</span>
              </Link>
            </Grid>
          </BoxedContainer>
        </Box>
      </div>
    </Layout>
  )
}

export default TraefikPage

export const query = graphql`
  {
    traefik_announcement: ghostPage(
      slug: { eq: "traefik-updates-announcement-banner" }
    ) {
      html
    }

    etcd: file(relativePath: { eq: "traefik/ecosystem/etcd/etcd.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    marathon: file(
      relativePath: { eq: "traefik/ecosystem/marathon/marathon.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    rancher: file(
      relativePath: { eq: "traefik/ecosystem/rancher/rancher.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    jaeger: file(relativePath: { eq: "traefik/ecosystem/jaeger/jaeger.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    opentracing: file(
      relativePath: { eq: "traefik/ecosystem/opentracing/opentracing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    zipkin: file(relativePath: { eq: "traefik/ecosystem/zipkin/zipkin.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    datadog: file(
      relativePath: { eq: "traefik/ecosystem/datadog/datadog.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    prometheus: file(
      relativePath: { eq: "traefik/ecosystem/prometheus/prometheus.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    influxdata: file(
      relativePath: { eq: "traefik/ecosystem/influx-data/influx-data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    amazonEcs: file(relativePath: { eq: "traefik/ecosystem/amazon-ecs.png" }) {
      childImageSharp {
        gatsbyImageData(width: 138, height: 88, layout: FIXED)
      }
    }

    related_resources: allGhostPost(
      filter: {
        tags: { elemMatch: { slug: { eq: "hash-traefik-related-resource" } } }
      }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          slug
          primary_tag {
            name
            slug
          }
          tags {
            name
            slug
            visibility
          }
        }
      }
    }

    current_page: ghostPage(slug: { eq: "traefik" }) {
      ...PageMetaFields
    }
  }
`
