import React from "react"
import { Heading, Paragraph, StyledLink, Box } from "../../elements"

const UseCase = ({ title, text, link, icon }) => (
  <Box>
    <img src={icon} alt={title} />
    <Heading as="h3" variant="sm" color="text.inverted.base" mt="24px">
      {title}
    </Heading>
    <Paragraph variant="sm" color="#b3bac0" mt="16px">
      {text}
    </Paragraph>
    {link.isExternal ? (
      <StyledLink icon="arrow" href={link.href} color="#54b4cd" mt="26px">
        Learn more
      </StyledLink>
    ) : (
      <StyledLink icon="arrow" to={link.href} color="#54b4cd" mt="26px">
        Learn more
      </StyledLink>
    )}
  </Box>
)

export default UseCase
